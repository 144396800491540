import React from "react";
import "../Css/Home.css";

const Video = () => {
  return (
    <div className="banner-2">
      <div className="banner-2-sub">
        <img
          src="https://uspoloassn.in/cdn/shop/files/13SEP23-THUMBNAIL-USPA-GL-DSK.jpg?v=1695191279"
          alt=""
        />
      </div>
    </div>
  );
};

export default Video;
